import * as React from "react";
import Link from "../../components/link";
import { Subtitle } from "@quandoo-dev/cookbook/lib/typography";
import { GatsbyImageProps } from "gatsby-image";
import FeatureImage from "../../components/post/feature-image";
import Grid from "@quandoo-dev/cookbook/lib/grid";
import { Box } from "@quandoo-dev/cookbook/lib/layout";
import RichText from "../rich-text";
import styled from "styled-components";


interface Contact {
    subtitle: string;
    email: string;
    richBody: {
        json: string;
    };
    locale: {
        slug: string;
    };
    image: GatsbyImageProps;
    slug: string;
}

interface ContactProps {
    page: Contact;
}

const TextAlign = styled.div`
    @media (min-width: 1024px) {
        text-align: center;
    }
`

const Contact: React.FC<ContactProps> = ({
    page
}) => {
    return (
        <Grid.Item mb="xxxl">
            <TextAlign>
                <Subtitle style={{ fontSize: "22px", lineHeight: "32px" }} size={1} mb={["24px", "24px", "24px", "24px"]}>{page.subtitle}</Subtitle>
            </TextAlign>
            <Grid.Row>
                <Grid.Item offset={2} columns={[12, 12, 4, 4]}>
                    <Box display={["none", "none", "block", "block"]} width={["327px", "444px", "293px", "384px"]}>
                        <FeatureImage
                            // alt={card.profileImage.description}
                            fluid={page.image.fluid}
                        />
                    </Box>
                </Grid.Item>
                <Grid.Item>
                    <Box width={["", "", "293px", "384px"]}>
                        <RichText document={page.richBody.json} />
                        <a href="mailto:editorial@quandoo.com" style={{ textDecoration: "none", color: "#1870C3" }}>{page.email}</a>
                    </Box>
                    <Box display={["block", "block", "none", "none"]} mt="48px" mx="auto" width={["327px", "327px", "444px", "293px", "384px"]}>

                        <FeatureImage
                            // alt={card.profileImage.description}
                            fluid={page.image.fluid}
                        />
                    </Box>
                </Grid.Item>
            </Grid.Row>
        </Grid.Item>
    );
};


export default Contact;
