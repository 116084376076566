import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Breadcrumbs, { Crumb } from "../components/breadcrumbs";
import Grid from "@quandoo-dev/cookbook/lib/grid";
import { Helmet } from "react-helmet";
import { Heading } from "@quandoo-dev/cookbook/lib/typography";
import { Box } from "@quandoo-dev/cookbook/lib/layout";
import RichText from "../components/rich-text";
import BioCard from "../components/cards/bio-card";
import Contact from "../components/contact-page/contact-page";
import styled from "styled-components";
import { getOneTrustId } from '../utils/one-trust-ids';

interface BlogProps {
  data: any;
}

const TextAlign = styled.div`
  @media (min-width: 1024px) {
    text-align: center;
  }
`;

const AboutPage: React.FC<BlogProps> = props => {
  const {
    data: {
      contentfulPage: { title, slug, locale, sections },
    },
  } = props;

  const crumbs = [
    {
      // TODO: the string for "Home" should come from Contentful according to locale
      name: "Home",
      to: `/`,
      item: `${locale.domain}`,
    },
    {
      name: title,
      item: `${locale.domain}/${slug}`,
    } as Crumb,
  ];

  const ldJson = {
    "@context": "http://schema.org/",
    name: title,
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": `${locale.domain} / ${title}`,
    },
    publisher: {
      "@type": "Organization",
      name: "Quandoo",
      url: "QUISINE " + title,
      logo: {
        "@type": "ImageObject",
        url: `https:${locale.logo.fluid.src}`,
      },
    },
  };

  return (
    <Layout locale={locale.slug}>
      <Helmet
        htmlAttributes={{
          lang: locale.languageCode,
        }}
        title={title + " | " + locale.name}
        titleTemplate={title + " | " + locale.name}
        link={[
          {
            rel: "canonical",
            key: locale.domain + "/" + slug,
            href: locale.domain + "/" + slug,
          },
        ]}
        meta={[
          //{ name: `description`, content: description },
          { name: `robots`, content: `index, follow` },
          { name: `og:locale`, content: locale.languageCode },
          { name: `og:type`, content: `object` },
          { name: `og:title`, content: title + " | " + locale.name },
          //{ name: `og:description`, content: description },
          { name: `og:site_name`, content: locale.name },
          { name: `og:url`, content: locale.domain + "/" + slug },
          //{ name: `og:image`, content: topArticleImage.asset.fluid.src },
          //{ name: `twitter:card`, content: topArticleImage.asset.fluid.src },
          { name: `twitter:title`, content: title + " | " + locale.name },
          { name: `twitter:site`, content: "@" + locale.twitterAccountName },
        ]}
      >
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
        {locale.slug !== 'au' && <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"  type="text/javascript" charSet="UTF-8" data-domain-script={getOneTrustId(locale.slug)} ></script>}
        {locale.slug !== 'au' && <script type="text/javascript">function OptanonWrapper() { }</script>}
      </Helmet>
      <Breadcrumbs crumbs={crumbs} />
      {(slug.includes("contact") || slug.includes("kontakt") || slug.includes("contattaci")) ? (
        <TextAlign>
          <Heading
            mt={["20px", "20px", "28px", "28px"]}
            mb={["16px", "16px", "24px", "24px"]}
            size={1}
          >
            {title}
          </Heading>
        </TextAlign>
      ) : (
        <Heading
          mt={["20px", "20px", "28px", "28px"]}
          mb={["16px", "16px", "24px", "24px"]}
          size={1}
        >
          {title}
        </Heading>
      )}

      <Grid.Row mb="64px">
        {sections.map((c, index) => {
          switch (c.__typename) {
            case "ContentfulPostCopy":
              return (
                <Grid.Item key={index}>
                  <Box width={["", "", "690px", "904px"]} style={{fontSize:"20px"}}>
                    <RichText document={c.richBody.json} />
                  </Box>
                </Grid.Item>
              );
            case "ContentfulPostHeadline":
              return (
                <Grid.Item key={index} columns={[12, 12, 12, 12]}>
                  <Heading mt={["24px", "24px", "16px", "16px"]} size={2}>{c.title}</Heading>
                </Grid.Item>
              );

            case "ContentfulAuthor":
              return (
                <Grid.Item key={index} columns={[12, 12, 4, 4]}>
                  <BioCard author={c} locale={locale}></BioCard>
                </Grid.Item>
              );
            case "ContentfulPageContact":
              return <Contact key={index} page={c}></Contact>;
            default:
              return null;
          }
        })}
      </Grid.Row>
    </Layout>
  );
};

export const query = graphql`
  query($slug: String, $localeSlug: String!) {
    contentfulPage(slug: { eq: $slug }, locale: { slug: { eq: $localeSlug } }) {
      title
      slug
      locale {
        slug
        domain
        languageCode
        name
        twitterAccountName
        logo {
          fluid {
            src
          }
        }
      }
      sections {
        __typename
        ... on ContentfulPageContact {
          subtitle
          email
          richBody {
            json
          }
          image {
            fluid(maxWidth: 799) {
              ...GatsbyContentfulFluid_noBase64
            }
          }
        }
        __typename
        ... on ContentfulPostHeadline {
          title
        }
        __typename
        ... on Node {
          ... on ContentfulPostCopy {
            contentful_id
            richBody {
              json
            }
          }
          __typename
          ... on Node {
            ... on ContentfulAuthor {
              firstName
              lastName
              slug
              bio {
                bio
              }
              profileImage {
                fluid(maxWidth: 476) {
                  ...GatsbyContentfulFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default AboutPage;
