import * as React from "react";
import Link from "../../components/link";
import { Heading, Body } from "@quandoo-dev/cookbook/lib/typography";
import { GatsbyImageProps } from "gatsby-image";
import FeatureImage from "../../components/post/feature-image";
import { Flex, Box } from "@quandoo-dev/cookbook/lib/layout";
import styled from "styled-components";


interface Card {
    firstName: string;
    lastName: string;
    bio: {
        bio: string;
    };
    profileImage: GatsbyImageProps;
    slug: string;
}

interface bioCardProps {
    author: Card;
    locale: any;
}

const Card = styled.div`
    border: 1px solid #CBD4E1;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 27px;
    width: 328px;
    margin: 32px 0 0;
    @media (min-width: 600px) {
        width: 535px;
    }
    @media (min-width: 1024px) {
        width: 293px;
    }
    @media (min-width: 1400px) {
        width: 384px;
    }
`

const bioCard: React.FC<bioCardProps> = ({
    author, locale
}) => {
    return (
        <Card>
            <Link to={`/author/${author.slug}`}>
                <Flex>
                    <Box mr={["16px", "23px", "8px", "16px"]}>
                        <FeatureImage
                            // alt={card.profileImage.description}
                            fluid={author.profileImage.fluid}
                            style={{
                                borderRadius: "50%",
                                width: "70px",
                                verticalAlign: "top"
                            }}
                        />
                    </Box>
                    <Box style={{ alignSelf: "center" }}>
                        <Heading size={3}>{author.firstName}{' '}{author.lastName}</Heading>
                    </Box>
                </Flex>
            </Link>
            <Body color="heading" mt={["15px", "15px", "15px", "10px"]}>{author.bio.bio}</Body>
        </Card>
    );
};


export default bioCard;
